import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"

const AboutPage = () => {
  return (
    <Layout title="About Us">
      <Head title="About" description="The Irish Skeptics Society promotes the teaching and application of critical thinking skills."/>

      <h2 className="title">What is a Skeptic?</h2>
      <div className="content">
        <p>Put very simply, a skeptic is someone who is committed to logic, reason and critical thinking when making assessments or decisions.</p>
        <p>Skeptics understand all knowledge to be tentative and data dependent. No matter what we may believe to be true, if the data contradict that belief, it is wrong. The world is as it is, not as we might wish it to be.</p>
        <p>Along with empiricism and rationality, skepticism forms a critical part of the bedrock of science. The methods of science help us steer a course between an extreme "we can know nothing" skepticism and total credulity in which virtually anything may be accepted as valid or true.</p>
      </div>

      <h2 className="title">Skeptical, not Cynical</h2>
      <div className="content">
        <p>Skeptics tend to be an optimistic bunch who have a positive outlook on life. While the cynic expects negativity and disaster at every turn, the skeptic approaches life with a questioning attitude and a desire for objective evidence in support of claims made about the world. The more extravagant the claims, the greater the demand for clear supportive data.</p>
      </div>


      <h2 className="title">Skeptics are Open-Minded</h2>
      <div className="content">
        <p>Skeptics are sometimes accused of closed-mindedness, a criticism which is strongly rejected. The skeptic is open to the possibility of refutation of favoured ideas.</p>
        <p>The position most commonly taken is that current scientific knowledge is the best we have and that science, while limited, is the best tool we have for learning about the world. At the same time, we must be open to change in the future as science progresses.</p>
        <p>Without this position of openness, we cannot hope to move forward and we risk rejecting ideas which are potentially accurate and useful. If however, we are too accepting and not questioning enough we may end up spending a lot of time and energy on nonsense. The key is to find a good balance between these two extremes.</p>
      </div>

      <h2 className="title">Are Skeptics Anti-Religious?</h2>
      <div className="content">
        <p>No. People are sometimes concerned that skeptics are, by definition, anti-religious. This is not the case.</p>
        <p>There are skeptics who have religious beliefs and who distinguish clearly between questions concerning the material world (the realm of science), and a non-material world in which they believe and for which there is no physical evidence. Such beliefs may be viewed as a matter of choice, or as a consequence of particular cultural traditions.</p>
        <p>There are however, areas of overlap. If claims are made in the name of religion that concern physical phenomena and that can be tested, skeptics may engage in such testing. For example, claims of statues moving or of miraculous appearances or cures may be challenged.</p>
      </div>

      <h2 className="title">The goals of the Irish Skeptics Society</h2>
      <div className="content">
        <ul>
          <li>To promote a scientific and rational point of view.</li>
          <li>To promote the teaching and application of critical thinking skills.</li>
          <li>To promote the active questioning of claims in a variety of areas, which is noticeably absent at present.</li>
          <li>To provide a forum for debate, discussion and rational argument on a range of relevant topics.</li>
          <li>To provide an access point for media for skeptical responses to questionable claims.</li>
          <li>To encourage the active involvement of people from a wide range of backgrounds.</li>
        </ul>
      </div>
    </Layout>
  )
}

export default AboutPage